import cx from 'classnames';
import { PropsWithChildren, useEffect, useState, useId } from 'react';

import { createPortal } from 'react-dom';

import { PartialBy } from '../../../shared/utilities/types/PartialBy';

import { ModalService } from './modalService';
import style from './ModalSkeleton.module.css';
import { UseModalState } from './useModal';

// TODO: Rename medium to large
export type ModalSize = 'small' | 'medium' | 'fullscreen';

export type ModalSkeletonProps = PartialBy<UseModalState, 'open'> &
    PropsWithChildren<{
        size?: ModalSize;
        onClickOverlay?: () => void;
        className?: string;
    }>;

export function ModalSkeleton({
    isOpen,
    close,
    size = 'medium',
    onClickOverlay,
    className,
    children,
}: PropsWithChildren<ModalSkeletonProps>) {
    const modalId = useId();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isOpen) {
            ModalService.registerModal(modalId);
        } else {
            ModalService.unregisterModal(modalId);
        }

        return () => {
            ModalService.unregisterModal(modalId);
        };
    }, [isOpen, modalId]);

    const handleClickOverlay = () => {
        if (onClickOverlay) {
            onClickOverlay();
        } else {
            close();
        }
    };

    if (!mounted || !isOpen) {
        return null;
    }

    const modal = (
        <div
            className={cx(style.modalWrapper, {
                [style.small]: size === 'small',
                [style.large]: size === 'medium',
                [style.fullscreen]: size === 'fullscreen',
            })}
        >
            <div
                className={cx(style.overlay, 'animate', 'animate-fast', 'animation-fade-in')}
                onClick={handleClickOverlay}
            />
            <div className={cx(style.modal, 'animate', 'animation-slide-up', className)}>
                {children}
            </div>
        </div>
    );

    return createPortal(modal, document.getElementById('modal-wrapper') as HTMLElement);
}

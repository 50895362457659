import { SVGAttributes } from 'react';

type Props = SVGAttributes<SVGElement>;

export function IconAdd(props: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            data-testid="IconAdd"
            {...props}
        >
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>
    );
}

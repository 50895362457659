import cx from 'classnames';

import Image, { ImageProps } from 'next/image';

import { IconButton } from '../form/button/IconButton';
import { IconArrowLeft } from '../icons/ArrowLeft';
import { IconCross } from '../icons/Cross';

import style from './ModalImageHeader.module.css';
import { ModalSize } from './ModalSkeleton';

export type ModalImageHeaderPropsBase = {
    onClose?: () => void;
    onPreviousButtonClick?: () => void;
    size?: ModalSize;
};

// Variant pour le fit des images
type ImageCommonProps = Pick<ImageProps, 'src' | 'alt' | 'loader' | 'unoptimized'>;
export type CoverImageProps = {
    fit?: 'cover';
    image: ImageCommonProps;
};
export type ContainImageProps = {
    fit: 'contain';
    image: ImageCommonProps & Partial<Pick<ImageProps, 'width' | 'height'>>;
};

export type ModalImageHeaderProps = ModalImageHeaderPropsBase &
    (CoverImageProps | ContainImageProps);
export function isContainImage(
    props: ModalImageHeaderProps,
): props is ModalImageHeaderPropsBase & ContainImageProps {
    return props.fit === 'contain';
}

export function ModalImageHeader(props: ModalImageHeaderProps) {
    const { image, fit = 'cover', onClose, onPreviousButtonClick, size } = props;
    return (
        <div
            className={cx(style.header, {
                [style.big]: size !== 'small',
                [style.contain]: fit === 'contain',
            })}
        >
            <Image
                {...image}
                // Keep the image sizes in sync with the ModalSkeleton.module.css (--modal-small-width, --modal-large-width)
                sizes="(min-width: 960px) 960px, (min-width: 640px) 640px, 100vw"
                alt={image.alt}
                className={cx(style.image, {
                    [style.big]: size !== 'small',
                    [style.contain]: fit === 'contain',
                    imageCover: fit === 'cover',
                })}
                loading="eager"
                objectFit={fit}
                objectPosition="center"
                fill={fit === 'cover'}
                width={isContainImage(props) ? (props.image.width ?? 960) : undefined}
                height={isContainImage(props) ? (props.image.height ?? 960) : undefined}
                priority
            />
            {onClose ? (
                <IconButton onClick={onClose} Icon={IconCross} className={style.closeButton} />
            ) : null}
            {onPreviousButtonClick ? (
                <IconButton
                    onClick={onPreviousButtonClick}
                    Icon={IconArrowLeft}
                    className={style.previousButton}
                />
            ) : null}
        </div>
    );
}

import { ReactElement } from 'react';

export type FallbackFunction<ComponentProps> = (
    error: DesignSystemError,
    props: ComponentProps,
) => ReactElement | null;

export class DesignSystemError extends Error {
    public data: Record<string, unknown>;

    constructor(message: string, data?: Record<string, unknown>) {
        super(message);

        this.name = 'DesignSystemError';
        this.data = data ?? {};

        Object.setPrototypeOf(this, DesignSystemError.prototype);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DesignSystemError);
        }
    }
}

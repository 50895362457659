import cx from 'classnames';
import {
    ChangeEventHandler,
    forwardRef,
    InputHTMLAttributes,
    MouseEventHandler,
    SVGAttributes,
    useId,
    ReactElement,
} from 'react';

import { IconAdd } from '../../icons/Add';
import { IconRemove } from '../../icons/Remove';
import { IconButton } from '../button/IconButton';

import style from './Input.module.css';

type IncrementalButtonsProps = {
    inputProps: InputHTMLAttributes<HTMLInputElement> & {
        onChange?: ChangeEventHandler<HTMLInputElement>;
        'data-testid'?: string;
    };
};

const IncrementalButtonLeft = ({ inputProps }: IncrementalButtonsProps) => {
    const { value, name, onChange, min } = inputProps;
    if (!name || !onChange) {
        return null;
    }
    const decreaseValue = () => {
        const newValue = Number(value) - 1;
        if (min !== undefined && newValue < Number(min)) {
            return;
        }
        onChange({
            target: { value: `${newValue}`, name },
        } as React.ChangeEvent<HTMLInputElement>);
    };
    return (
        <IconButton
            Icon={IconRemove}
            onClick={decreaseValue}
            className={style.incrementalButton}
            data-testid={`${inputProps['data-testid']}_decrease`}
        />
    );
};

const IncrementalButtonRight = ({ inputProps }: IncrementalButtonsProps) => {
    const { value, name, onChange, max } = inputProps;
    if (!name || !onChange) {
        return null;
    }
    const increaseValue = () => {
        const newValue = Number(value) + 1;
        if (max !== undefined && newValue > Number(max)) {
            return;
        }
        onChange({
            target: { value: `${newValue}`, name },
        } as React.ChangeEvent<HTMLInputElement>);
    };
    return (
        <IconButton
            Icon={IconAdd}
            onClick={increaseValue}
            className={style.incrementalButton}
            data-testid={`${inputProps['data-testid']}_increase`}
        />
    );
};

export type Props = {
    label?: string;
    labelDetail?: string;
    centered?: boolean;
    description?: string;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLLabelElement>;
    Icon?: (props: SVGAttributes<SVGElement>) => ReactElement;
    onIconClick?: MouseEventHandler<HTMLButtonElement>;
    unit?: string;
    inputProps: InputHTMLAttributes<HTMLInputElement> & {
        onChange?: ChangeEventHandler<HTMLInputElement>;
        'data-testid'?: string;
    };
    className?: string;
    displayIncrementalButtons?: boolean;
};

export const Input = forwardRef<HTMLInputElement, Props>(
    (
        {
            label,
            labelDetail,
            centered,
            description,
            placeholder,
            error,
            disabled = false,
            onClick,
            Icon,
            onIconClick,
            unit,
            inputProps,
            className,
            displayIncrementalButtons = false,
        },
        ref,
    ) => {
        const id = useId();
        return (
            <div className={cx(style.wrapper, className)}>
                <label
                    htmlFor={id}
                    onClick={onClick}
                    className={cx({ [style.centered]: centered })}
                >
                    {label || description ? (
                        <div className={style.head}>
                            <div>
                                <span className="font-body-bold">{label}</span>
                                {labelDetail ? (
                                    <span className={cx(style.labelDetail, 'font-xs-regular')}>
                                        {labelDetail}
                                    </span>
                                ) : null}
                            </div>
                            <div className="font-xs-regular">{description}</div>
                        </div>
                    ) : null}
                    <div className={style.inputContainer}>
                        {displayIncrementalButtons && inputProps.type === 'number' ? (
                            <IncrementalButtonLeft inputProps={inputProps} />
                        ) : null}
                        <div
                            className={cx(style.inputWrapper, {
                                [style.inputError]: !!error,
                                [style.inputDisabled]: !!disabled,
                                [style.inputWrapperWithIncrementalButtons]:
                                    displayIncrementalButtons,
                            })}
                        >
                            <input
                                {...inputProps}
                                id={id}
                                ref={ref}
                                className={cx(
                                    style.input,
                                    'font-body-regular',
                                    inputProps.className,
                                )}
                                placeholder={placeholder}
                                disabled={disabled}
                            />
                            {unit ? <span className={style.inputUnit}>{unit}</span> : null}
                            {Icon && !onIconClick ? (
                                <span className={style.inputIcon}>
                                    <Icon />
                                </span>
                            ) : null}
                            {Icon && onIconClick ? (
                                <button
                                    className={style.inputIconAsButton}
                                    onClick={onIconClick}
                                    type="button"
                                >
                                    <Icon />
                                </button>
                            ) : null}
                        </div>
                        {displayIncrementalButtons && inputProps.type === 'number' ? (
                            <IncrementalButtonRight inputProps={inputProps} />
                        ) : null}
                    </div>
                    {error ? (
                        <div className={cx(style.textError, 'font-xs-regular')}>{error}</div>
                    ) : null}
                </label>
            </div>
        );
    },
);

import cx from 'classnames';
import { forwardRef, SVGAttributes, ReactElement } from 'react';

import style from './IconButton.module.css';

export type Props = React.ComponentPropsWithoutRef<'button'> & {
    Icon: (props: SVGAttributes<SVGElement>) => ReactElement;
    size?: 'S' | 'M';
};

export const IconButton = forwardRef<HTMLButtonElement, Props>(
    ({ className, Icon, size = 'M', ...props }, ref) => {
        return (
            <button
                {...props}
                ref={ref}
                className={cx(style.button, className, {
                    [style.isDisabled]: props.disabled,
                    [style.sizeS]: size === 'S',
                    [style.sizeM]: size === 'M',
                })}
                type="button"
            >
                <Icon className={style.icon} />
            </button>
        );
    },
);

IconButton.displayName = 'IconButton';

import cx from 'classnames';
import { ButtonHTMLAttributes } from 'react';

import { IconCross } from '../icons/Cross';

import style from './CrossButton.module.css';

export type CrossButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
export const CrossButton = (props: CrossButtonProps) => {
    return (
        <button type="button" {...props} className={cx(props.className, style.navItem)}>
            <IconCross className={style.icon} />
        </button>
    );
};

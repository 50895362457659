import cx from 'classnames';
import { PropsWithChildren } from 'react';

import style from './ModalBody.module.css';

export type ModalBodyProps = PropsWithChildren<{
    dark?: boolean;
    className?: string;
}>;

export function ModalBody({ dark, className, children }: ModalBodyProps) {
    return <div className={cx(style.body, className, { [style.dark]: dark })}>{children}</div>;
}

import cx from 'classnames';
import { ReactElement } from 'react';

import { Typography } from '../typography/Typography';

import { CrossButton } from './CrossButton';
import style from './ModalHeader.module.css';

export type ModalHeaderProps = {
    title?: string | ReactElement;
    dark?: boolean;
    onClose?: () => void;
};

export function ModalHeader({ title, dark, onClose }: ModalHeaderProps) {
    return (
        <div className={cx(style.header, { [style.dark]: dark })}>
            <div className={style.title}>
                {title ? <Typography scale="primary-s-extra-bold">{title}</Typography> : null}
            </div>
            <div className={style.rightButton}>
                {onClose ? <CrossButton onClick={onClose} className={style.crossButton} /> : null}
            </div>
        </div>
    );
}

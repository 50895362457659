import style from './DotsLoader.module.css';

export function DotsLoader() {
    return (
        <span className={style.loader} data-testid="loader-dot">
            <span className={style.dot}></span>
            <span className={style.dot}></span>
            <span className={style.dot}></span>
        </span>
    );
}

export class ModalService {
    private static openModals = new Set<string>();

    static registerModal(modalId: string): void {
        this.openModals.add(modalId);

        if (this.openModals.size === 1) {
            const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollBarWidth}px`;
            document.body.classList.add('no-scroll');
        }
    }

    static unregisterModal(modalId: string): void {
        this.openModals.delete(modalId);

        if (this.openModals.size === 0) {
            document.body.style.paddingRight = `0px`;
            document.body.classList.remove('no-scroll');
        }
    }
}

import cx from 'classnames';

import { Button } from '../form/button/Button';

import style from './ModalFooter.module.css';

export type ModalFooterProps = {
    dark?: boolean;
    primaryButton?: {
        label: string;
        disabled?: boolean;
        onClick: () => void;
    };
    secondaryButton?: {
        label: string;
        disabled?: boolean;
        onClick: () => void;
    };
};

export function ModalFooter({ dark, primaryButton, secondaryButton }: ModalFooterProps) {
    const hasButton = !!secondaryButton || !!primaryButton;

    if (!hasButton) {
        return null;
    }

    const hasLeftButtons = !!secondaryButton;
    const hasRightButtons = !!primaryButton;
    const hasBothSides = hasLeftButtons && hasRightButtons;

    return (
        <div
            className={cx(style.footer, {
                [style.dark]: dark,
                [style.hasBothSides]: hasBothSides,
                [style.hasOnlyLeftButton]: hasLeftButtons && !hasRightButtons,
                [style.hasOnlyRightButton]: !hasLeftButtons && hasRightButtons,
            })}
        >
            {secondaryButton ? (
                <div
                    className={cx(style.buttons, style.secondaryButton, {
                        [style.hasBothSides]: hasBothSides,
                    })}
                >
                    <Button
                        priority="tertiary"
                        disabled={secondaryButton.disabled}
                        onClick={secondaryButton.onClick}
                    >
                        {secondaryButton.label}
                    </Button>
                </div>
            ) : null}
            {primaryButton ? (
                <div
                    className={cx(style.buttons, style.primaryButton, {
                        [style.hasBothSides]: hasBothSides,
                    })}
                >
                    <Button
                        priority="primary"
                        disabled={primaryButton.disabled}
                        onClick={primaryButton.onClick}
                    >
                        {primaryButton.label}
                    </Button>
                </div>
            ) : null}
        </div>
    );
}

import { SVGAttributes } from 'react';

type Props = SVGAttributes<SVGElement>;

export function IconRemove(props: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            data-testid="IconRemove"
            {...props}
        >
            <path d="M19 13H5v-2h14v2z" />
        </svg>
    );
}

import cx from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';

import style from './Modal.module.css';
import { ModalBody } from './ModalBody';
import { ModalFooter, ModalFooterProps } from './ModalFooter';
import { ModalHeader, ModalHeaderProps } from './ModalHeader';
import { ModalImageHeader, ModalImageHeaderProps } from './ModalImageHeader';
import { ModalSkeleton, ModalSkeletonProps } from './ModalSkeleton';

type BaseModalProps = Omit<ModalSkeletonProps, 'children' | 'className'> &
    PropsWithChildren<{
        onClose?: () => void;
        hasCloseButton?: boolean;
        dark?: boolean;
        className?: string;
    }>;

export type ModalProps = BaseModalProps &
    (DefaultHeaderProps | ImageHeaderProps) &
    (DefaultFooterProps | CustomFooterProps);

// Header variants
type ComputedHeaderProps = 'onClose' | 'onPreviousButtonClick' | 'size';
type DefaultHeaderProps = {
    headerVariant?: 'default';
    image?: never;
} & Omit<ModalHeaderProps, ComputedHeaderProps>;
type ImageHeaderProps = {
    headerVariant: 'image';
    title?: never;
} & Omit<ModalImageHeaderProps, ComputedHeaderProps>;
function isDefaultHeader(props: ModalProps): props is BaseModalProps & DefaultHeaderProps {
    return props.headerVariant !== 'image';
}

// Footer variants
type DefaultFooterProps = ModalFooterProps & {
    footerVariant?: 'default';
    footer?: never;
};
type CustomFooterProps = {
    footerVariant: 'custom';
    footer?: ReactElement;
};
function isDefaultFooter(props: ModalProps): props is BaseModalProps & DefaultFooterProps {
    return props.footerVariant !== 'custom';
}

export function Modal(props: ModalProps) {
    const {
        title,
        hasCloseButton = true,
        dark,
        className,
        children,
        onClose,
        ...modalSkeletonProps
    } = props;
    const { close } = modalSkeletonProps;

    const handleClose = () => {
        onClose?.();
        close();
    };

    const commonHeaderProps = {
        onClose: hasCloseButton ? handleClose : undefined,
    };

    return (
        <ModalSkeleton
            {...modalSkeletonProps}
            onClickOverlay={handleClose}
            className={cx(style.modal, className)}
        >
            {isDefaultHeader(props) ? (
                <ModalHeader {...commonHeaderProps} title={title} dark={dark} />
            ) : (
                <ModalImageHeader
                    {...commonHeaderProps}
                    image={props.image}
                    size={modalSkeletonProps.size}
                    fit={props.fit}
                />
            )}
            <ModalBody className={style.modalBody} dark={dark}>
                {children}
            </ModalBody>
            {isDefaultFooter(props) ? (
                <ModalFooter
                    primaryButton={props.primaryButton}
                    secondaryButton={props.secondaryButton}
                    dark={dark}
                />
            ) : (
                props.footer
            )}
        </ModalSkeleton>
    );
}

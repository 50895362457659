import cx from 'classnames';
import { HTMLAttributes, SVGAttributes, ReactElement } from 'react';

import style from './Badge.module.css';

type BadgeProps = HTMLAttributes<HTMLDivElement> & {
    bgColor: 'green-600' | 'custom-brand-red';
    Content: string | ((props: SVGAttributes<SVGElement>) => ReactElement);
};

export function Badge({ bgColor, children, className, Content, ...props }: BadgeProps) {
    const icon =
        typeof Content === 'string' ? <>{Content[0]}</> : <Content className={style.iconSvg} />;

    return (
        <div
            data-testid="Badge"
            {...props}
            className={cx('font-xs-bold', style.badge, className)}
            // eslint-disable-next-line react/forbid-dom-props
            style={{
                backgroundColor: `var(--color-${bgColor})`,
            }}
        >
            {icon}
        </div>
    );
}

import { useCallback, useMemo, useState } from 'react';

export type UseModalState = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
};

export function useModal(): UseModalState {
    const [isOpen, setIsOpen] = useState(false);
    const open = useCallback(() => {
        setIsOpen(true);
    }, []);
    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    return useMemo(() => ({ isOpen, open, close }), [close, isOpen, open]);
}
